


@media (min-width: 350px) {
  .pickContainer2 {
    align-items: center;
margin-top: 15px;
    margin-left: 1%;
    margin-right: 1%;
    background-color: whitesmoke;
    color: black;

    padding: 2%;
  }
  .breadContain{
    margin-top: 15px;
    margin-left: 3%;
    margin-right: 3%;
text-align: left;  }
}
@media (min-width: 900px) {
  .pickContainer2 {
    align-items: center;
    margin-top: 15px;
    margin-left: 5%;
    margin-right: 5%;
    background-color: whitesmoke;
    color: black;


  }
  .breadContain{
    margin-top: 15px;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
  }
}
@media (min-width: 1400px) {
  .pickContainer2 {
    align-items: center;
    margin-top: 15px;
    margin-left: 10%;
    margin-right: 10%;
    background-color: whitesmoke;
    color: black;


  }
  .breadContain{
    margin-top: 15px;
    margin-left: 10%;
    margin-right: 10%;
    text-align: left;
  }
}
@media (min-width: 1750px) {
  .pickContainer2 {
    align-items: center;
    margin-top: 15px;
    margin-left: 20%;
    margin-right: 20%;
    background-color: whitesmoke;
    color: black;

  }
  .breadContain{
    margin-top: 15px;
    margin-left: 20%;
    margin-right: 20%;
    text-align: left;
  }
}

.price {
  font-size: xx-large;
}

.page{
  background-color: lightgray;
}
