.main {
  padding: 0em;
position: relative;
}

.subtitleIcon {
  position: absolute;
  top: 1em;
  left: 1em;
}
.subtitle {
  position: absolute;
  left: 0px;
  bottom: 15px;
  width: 100%;
  height: 60px;
  padding: 10px;
  background-color: black;
  opacity: 0.5;
  color: white;
}
.seekBar {
  margin-top: 5px;
  flex-grow: 2;
}
.buttons {
  flex-grow: 0;
}

.bar {
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 1s;
}

.barInvisble {
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s;
}

.barLeft {
  width: 88%;
  display: flex;
  float: left;
}

.barRight {
  width: 10%;
  float: right;
}

.controlbar {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  z-index: 1;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
}
.buttonIco {
  width: 15px;
  height: 15px;
}

.react-player {
  width: 50%;
  height: 50%;
}
.suby {
  width: 11px;
  height: 11px;
  color: gray;
}
