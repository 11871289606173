@media (min-width: 1000px) {
  .website {
    margin-top: 4.5em;
    margin-left: 30em;
  }
  .Iphone {
    margin-left: 7em;
  }
}
@media (min-width: 1350px) {
  .website {
    margin-top: 3em;
    margin-left: 40em;
  }
  .Iphone {
    margin-left: 17.5em;
  }
}
@media (min-width: 1700px) {
  .website {
    margin-top: 2em;
    margin-left: 49.6em;
  }
  .Iphone {
    margin-left: 27em;
  }
}
.website {
  position: absolute;

  z-index: 1;
  height: 55em;
  padding-top: 3.7%;
  padding-left: 0.1%;
  width: 28.125em;
}

.Background {
  position: absolute;
  z-index: 0;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  pointer-events: none;
}
.Iphone {
  margin-top: 6em;
  height: 60em;
  position: absolute;
  z-index: 2;
  pointer-events: none;
}

.mbDetect {
  position: fixed;
  font-size: xx-large;
  color: red;
  background-color: black;
  opacity: 0.7;
  z-index: 1502;
  padding: 5%;
  width: 100%;
  height: 100%;
}
.wrapperContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1000;
}
.wrapper {
  z-index: 1000;
  height: 1850px;
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background-color: black;
  opacity: 0.7;
  overflow: hidden;
}

.hidden {
  overflow: hidden;
}

.trapper {
  z-index: 1001;
  height: 100%;
  width: 100%;
  margin-top: 10%;
  position: absolute;
  align-items: center;
  overflow: hidden;
}

.checkmark__circle {
  opacity: 1;
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: white;
  fill: none;
  animation: stroke 1s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  animation-iteration-count: 1;
}

.checkmark {
  opacity: 1;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  display: block;
  stroke-width: 3;
  stroke: black;
  stroke-miterlimit: 10;
  margin: 10% auto;
  animation: fill 1s ease-in-out 1s forwards, scale 1s ease-in-out 1s both;
  animation-iteration-count: 1;
}

.checkmark__check {
  opacity: 1;
  transform-origin: 70% 70%;
  stroke-dasharray: 100;
  stroke-dashoffset: 100;
  animation: stroke 2.5s cubic-bezier(0.65, 0, 0.45, 1) 1 forwards;
  animation-iteration-count: infinite;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 250px white;
  }
}

.modal{
  z-index: 101010;
  position: absolute;
}