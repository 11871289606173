.main {
  width: 2em;
  height: 2em;
}

.main:hover {
  transform: scale(1.2);
  transition-duration: 0.5s;
}

.hidden {
  width: 2em;
  height: 2em;
}

.hidden:hover {
  transform: scale(1.2);
  transition-duration: 0.5s;
}

.nonActive
{
  visibility: hidden; 
 }