.page{
    background-color: lightgray;
}
.notFound{
    margin-top: 1%;
    margin-left: 20%;
    margin-right: 20%;
    background-color: whitesmoke;
    padding: 2%;
}
.notText{
  
    font-size: larger;
    font-weight: 600;
    width: 50%;
}

