/* https://www.w3schools.com/css/tryit.asp?filename=trycss3_flexbox_flex */


.main {

  width: 100%;
  height: 100%;
  margin: 0px;
}


.desktop {
  width: 100%;
  height: 100%;
}

.mobile {
  width: 400px;
  height: 770px;
  overflow: scroll;
}

.cont {
  width: 100%;
  height: 100%;
}

.conSide {
  width: 500px;
}

.conMain {
  width: 100%;
}

.ui.segment {
  padding-top: 0px;
}

.flexcontainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  height: 100%;

  background: rgb(0, 0, 0);
  background: linear-gradient(180deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(87, 87, 87, 1) 100%);

}

.flexitemsSide {
  order: 0;
  flex: 0 1 auto;
  align-self: auto;
  height: 100%;
}

.flexitemsMain {
  order: 0;
  flex: 1 1 auto;
  align-self: auto;
  height: 100%;
}

.fillit {
  position: absolute;
  z-index: 200;
  background-color: blanchedalmond;
  width: 100%;
  height: 100%;
}

.debugView {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 1em;
  background: orange;
  border-radius: 5px;
  color: black;
  opacity: 0.5;
  width: 150px;
}