@media (min-width : 350px) {
  .footerContainer {
    margin-left: 1%;
    margin-right: 1%;
    background-color: lightgray;
    margin-top: 70px;
  
  }
}
@media (min-width : 1400px) {
  .footerContainer {
    margin-left: 10%;
    margin-right: 10%;
    background-color: lightgray;
    margin-top: 70px;
  
  }
}
@media (min-width : 1720px) {
  .footerContainer {
    margin-left: 20%;
    margin-right: 20%;
    background-color: lightgray;
    margin-top: 70px;
  
  }
}
.footer1 {
  font-size: 15px;
}

.avasagButton {
 max-width: 13px;
 margin-right: 5px ;
 
}
.logo1 {
max-width: 20px;
  max-height: 17px;
  margin-right: 5px;
  
}
.divider1{
width: 100% ;
}