@media (min-width: 350px) {
  .master{
    margin-top: 15px;
    margin-left: 1%;
    margin-right: 1%;
    background-color: whitesmoke;
padding: 2%; }
  .imgAng {
    width: 350px;
    height: 150px;
  }
  .angebote {
    font-size: larger;
    font-weight: bolder;
  }
  .breadContain {
    margin-top: 13px;
    margin-left: 0%;
    margin-right: 0%;
    text-align: center;
  }
  .ticketFlex {
    display: flex;
    flex-wrap: wrap;
  }
  .ticketBing2 {
    margin-left: 1%;
  width: 80%;
  
  }
  .ticketBing8
{
  margin-left: 1%;
  margin-top: 0.625em;

}
.ticketBing3 {
  margin-right: 3%;
  margin-left: 1%;
  margin-bottom: 0%;
  margin-top: 20px;
  font-weight: 900;
}
.ticketBing4 {
margin-top: 10px;
}
.ticketBing7 {

  margin-right: 20px;
  margin-top: 20px;
  margin-left: 2%;

} .ticketBing6
{
 margin-left: 1%;
 margin-top: 10px;
width: 80%;
}
}
@media (min-width: 900px) {
  .master{
    margin-top: 15px;
    margin-left: 5%;
    margin-right: 5%;
    background-color: whitesmoke;
    padding: 2%;

  }
  .ticketBing3 {
    margin-right: 0px;
    margin-left: 1%;
    margin-top: 10px;
    
    font-weight: 900;
  }
  .ticketFlex {
    display: flex;
   
    flex-wrap: wrap;
 
  }
  .ticketBing2 {
    margin-left: 1%;
    margin-right: 5px;
  width: 19%;
  
  }

  .breadContain {
    margin-top: 13px;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
  }
  .ticketBing8 {
    margin-left: 0%;
    margin-top: 10px;

  
    
  }
  .ticketBing4 {
    margin-left: 1%;
    margin-top: 0px;
  ;
   
  }
  .ticketBing7 {

    margin-right: 20px;
    margin-top: 20px;
    
    margin-left: 2%;
  
  } .ticketBing6
  {
   margin-left: 1%;
   margin-top: 10px;
   width: 19%;
   margin-right: 5px;
 }
}

@media (min-width: 1400px) {
  .master{
    margin-top: 15px;
    margin-left: 10%;
    margin-right: 10%;
    background-color: whitesmoke;
    padding: 2%;  }
    .ticketBing3 {
      margin-right: 0px;
      margin-left: 1%;
      margin-top: 10px;
      font-weight: 900;
    }
  .ticketFlex {
    display: flex;
   
    flex-wrap: wrap;
 
  }

  .breadContain {
    margin-top: 13px;
    margin-left: 10%;
    margin-right: 10%;
    text-align: left;
  }
  .ticketBing4 {
    margin-left: 1%;
    margin-top: 0px;
  ;
   
  }
  .ticketBing7 {

    margin-right: 20px;
    margin-top: 20px;
    
    margin-left: 2%;
  
  } .ticketBing6
  {
   margin-left: 1%;
   margin-top: 10px;
 
 }
 .ticketBing8 {

  margin-left: 0%;
  width: 30%;
    
    }
}

@media (min-width: 1750px) {
  .master{
    margin-top: 15px;
    margin-left: 20%;
    margin-right: 20%;
    background-color: whitesmoke;
    padding: 2%;

  }
  .ticketFlex {
    display: flex;
    flex-wrap: wrap;
  }
  .ticketBing8 {

margin-left: 0%;
width: 30%;
  
  }
  .ticketBing3 {
    margin-left: 1%;
    margin-top: 10px;
    font-weight: 900;
  }
  .breadContain {
    margin-top: 13px;
    margin-left: 20%;
    margin-right: 20%;
    text-align: left;
  }
  .ticketBing4 {
    margin-left: 1%;
    margin-top: 0px;
  ;
   
  }
  .ticketBing7 {

    margin-right: 20px;
    margin-top: 20px;
    
    margin-left: 2%;
  
  }
  .ticketBing6
 {
  margin-left: 1%;
  margin-top: 10px;

} }

.lvbelc5 {
  width: 75px;

}
.ticketBing {
  width: 100%;
  margin-bottom: 1%;
  margin-left: 1%;

}
.ticketBing1 {
  margin-left: 1%;
  margin-top: 10px;
  font-weight: 900;
margin-right: 15px;
}


.ticketBing5 {
  margin-left: 1%;
  margin-top: 20px;
  font-weight: 900;


}





.ticketBing9{
  margin-left: 1%;
margin-top: 20px;
}

.lvbelc1 {
  padding-left: 15px;
  padding-right:15px;

}
.page {
  background-color: #d3d3d3;
}
