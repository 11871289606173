.Background {
    position: absolute;
    z-index: 0;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  pointer-events: none;
  }
.phone{
    margin-left: 35%;
}
.Iphone {
    margin-left: 27em;
    margin-top: 6em;
    height: 60em;
    position: absolute;
    z-index: 2;
  pointer-events: none;
  
  }
.image{
    z-index: -1;
    position: absolute;
}
.hour{
text-align: center;
    color: white;

}
.phone{
    margin-top: 3em;
    margin-left: 49em;
    position: absolute;
    z-index: 500;
   width: 430px;
   height: 900px;

}
.bumHead{
    margin-left: -5%;
    font-size: 85px;
}
.bumDead{
    margin-left: -5%;
    margin-top: -15px;
   font-size: 20px;
}
.pushNot{

    padding-top: 15px;
    padding-left: 15px;
margin-left: 12%;
    opacity: 0.8;
    background-color: azure;
    width: 305px;
    height: 100px;
    border-radius: 5%;
    text-align: left;
    cursor: pointer;
}
.pushHead{
    color: black;
    display: flex;
    padding-top: 10px;
    padding-left: 15px;
    margin-left: 12%;
    opacity: 1;
    background-color: azure;
    width: 305px;
    height: 50px;
    border-radius: 5%;
    text-align: left;
    margin-bottom: -7px;
    cursor: pointer;
}
.item1{
    flex-grow: 0;
    flex-shrink: 0;
}
.item2{
margin-left: 10%;
margin-top: 2px;
font-size: 20px;
}
.klick{
    font-size: 10px;
}
.notInfo{
    color: black;
    margin-top: 10px;
}
.notHead{
 margin-top: -7%;
 margin-left: 18%
}
.headComp{
    color: black;

}