
    .ticketContainer_mo{
        margin-top: 15px;
        display: flex;
        margin-left: 1%;
        margin-right:1%;
        background-color: whitesmoke;
        padding: 2%;
    } 

    .breadContain_mo{
        margin-top: 15px;
        margin-left: 3%;
        text-align: center;
    }

@media (min-width: 900px) {
    .ticketContainer{
        display: flex;
        margin-top: 15px;
        margin-left: 5%;
        margin-right: 5%;
        background-color: whitesmoke; 
        padding: 2%;
    }
    .breadContain{
        margin-top: 15px;
        margin-left: 5%;
        margin-right: 5%;
        text-align: left;
    }
}
@media (min-width: 1400px) {
    .ticketContainer{
        display: flex;
        margin-top: 15px;
        margin-left: 10%;
        margin-right:10%;
        background-color: whitesmoke; 
        padding: 2%;
    }
    .breadContain{
        margin-top: 15px;
        margin-left: 10%;
        margin-right: 10%;
        text-align: left;
    }
}
@media (min-width: 1750px) {
    .ticketContainer{
        margin-top: 15px;
        display: flex;
        margin-left: 20%;
        margin-right:20%;
        background-color: whitesmoke;
    }
    .breadContain{
        margin-top: 15px;
        margin-left: 20%;
        margin-right: 20%;
        text-align: left;
    }

}
.ticketBing1{
width: 60%;
}
.ticketBing2{
    width: 40%;

}
.ticketBong{
    max-width: 30%;
    max-height: 170px;
}
.page{
    background-color: lightgray;
}
.noTicket{
    font-size: x-large;
    font-weight: 700;
}
.icon {
    cursor: url("../../avasag.png"), auto;
}    .VideoPlayer{
    max-width: 30%;
    
}