
.pict2 {
  max-width: 100%;
  min-height: 100%;
    
   }
   .subText {
     font-size: 15px;
     font-weight: 1;
   }
   .page {
     background-color: lightgray;
   }
   .bumText {
     font-weight: 200;
     font-size: medium;
     margin-top: 2%;
     margin-left: 4%;
   
     margin-right: 4%;
   }
   .bumHead {
     font-weight: 700;
     font-size: large;
     margin-left: 4%;

     margin-right: 4%;
   }
  
   

   
  
   
   @media (min-width: 350px) {
   
     .landBum {
      
      padding: 2%;
      margin-top: 15px;
       margin-left: 1%;
       margin-right: 1%;
       display: flex;
       flex-wrap: wrap;
       justify-content: flex-start;
     
     }
     .landBing {
padding: 2%;
margin-top: 15px;
       margin-left: 1%;
       margin-right: 1%;
       display: flex;
       flex-wrap: wrap;
       justify-content: flex-start;
       
     }
     .landBing1 {
      padding: 2%;
      background-color: whitesmoke;
      width: 100%;
      min-height: 200px;
    }
    .landBing2 {
      background-color: whitesmoke;
      max-height: 15%;
      max-width: 100%;
      min-width: 100%;
    }
    .landBing3 {
      background-color: whitesmoke;

      margin-left: 2%;
      max-width: 100%;
      min-width: 200px;
    }
    .landBum1 {
      padding: 2%;
      background-color: whitesmoke;
      width: 100%;
      min-height: 150px;
    }


    
   
   
     .breadContain {
     margin-left: 3%;

      margin-top: 15px;
   text-align: left;
     }
   }
   @media (min-width: 900px) {
   
    .landBum {
     padding: 0%;
      margin-top: 15px;
      margin-left: 5%;
      margin-right: 5%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .landBing {
     padding: 0%;
      margin-top: 15px;
      margin-left: 5%;
      margin-right: 5%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
    .breadContain {
      margin-top: 15px;
      margin-left: 5%;
      margin-right: 5%;
      text-align: left;
     }
 
     .landBum1 {
       width: 100%;
       min-height: 150px;
     }
     
     .landBum3 {
       background-color: whitesmoke;
       margin-left: 8%;
     }
     .landBing1 {
       width: 65%;
       min-height: 200px;
     }
 
     .landBing3 {
       background-color: whitesmoke;
       margin-left: 2%;
       max-width: 33%;
       min-width: 200px;
     }
  }
   @media (min-width: 1400px) {
   
     .landBum {
      padding: 0%;
       margin-top: 1%;
       margin-left: 10%;
       margin-right: 10%;
       display: flex;
       flex-wrap: wrap;
       justify-content: flex-start;
     }
     .landBing {
      padding: 0%;
       margin-top: 1%;
       margin-left: 10%;
       margin-right: 10%;
       display: flex;
       flex-wrap: wrap;
       justify-content: flex-start;
     }
     .breadContain {
      margin-top: 15px;
       margin-left: 10%;
       margin-right: 10%;
       text-align: left;
      }
  
      .landBum1 {
        width: 100%;
        min-height: 150px;
      }
      
      .landBum3 {
        background-color: whitesmoke;
        margin-left: 8%;
      }
      .landBing1 {
        width: 65%;
        min-height: 200px;
      }
  
      .landBing3 {
        background-color: whitesmoke;
        margin-left: 2%;
        max-width: 33%;
        min-width: 200px;
      }
   }
   @media (min-width: 1750px) {
     .breadContain {
      margin-top: 15px;
       margin-left: 20%;
       margin-right: 20%;
       text-align: left;
     }
     .landBum {
      padding: 0%;
      margin-top: 15px;
       margin-left: 20%;
       margin-right: 20%;
       display: flex;
       flex-wrap: wrap;
       justify-content: flex-start;
     }
     .landBing {
      padding: 0%;
      margin-top: 15px;
      margin-left: 20%;
      margin-right: 20%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
     .headContain {
       margin-top: 4%;
       margin-left: 20%;
       margin-right: 20%;
     }
     .landBum1 {
      width: 100%;
      min-height: 150px;
    }
    
    .landBum3 {
      background-color: whitesmoke;
      margin-left: 8%;
    }
    .landBing1 {
      width: 65%;
      min-height: 200px;
    }

    .landBing3 {
      background-color: whitesmoke;
      margin-left: 2%;
      max-width: 33%;
      min-width: 200px;
    }
   }
   