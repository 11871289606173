.main {
  position:absolute;
  border-radius: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  padding: 0em;
  top: 199px;
  right: 100px;
  z-index: 3000;
  background: rgb(124, 114, 114);
}
