@media (min-width: 350px) {
  .breadContain {
    
    margin-left: 3%;
    margin-right: 3%;
    text-align: left;
    margin-top: 15px;
  }
  .master {
    
    margin-left: 1%;
    margin-right: 1%;
    margin-top: 15px;
    background-color: whitesmoke;
    padding: 5%;
  }
  .flexPawn3{

    width: 100%
}
.headPawn{
  margin-left: 5%;
  margin-top: 5px;
  font-size: large;
  font-weight: 700;
}
.subPawn{
  margin-top: 25px;
  font-size: medium;
  font-weight: 400;
}
}
@media (min-width: 900px) {
  .breadContain {
    margin-left: 5%;
    margin-right: 5%;
    text-align: left;
    margin-top: 15px;
  }
  .master {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 15px;
    background-color: whitesmoke;
    padding: 1%;
  }
  .flexPawn3{

    width: 30%
}
.headPawn{
  margin-left: 0%;
  margin-top: 5px;
  font-size: large;
  font-weight: 700;
}
.subPawn{
  margin-top: 25px;
  font-size: medium;
  font-weight: 400;
}
}
@media (min-width: 1400px) {
    .breadContain {
      margin-left: 10%;
      margin-right: 10%;
      text-align: left;
      margin-top: 15px;
    }
    .master {
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 15px;
      background-color: whitesmoke;
      padding: 1%;
    }
    .flexPawn3{

      width: 30%
  }
  .headPawn{
    margin-left: 0%;
    margin-top: 5px;
    font-size: large;
    font-weight: 700;
}
.subPawn{
    margin-top: 25px;
    font-size: medium;
    font-weight: 400;
}
  }
  @media (min-width: 1750px) {
    .breadContain {
      margin-left: 20%;
      margin-right: 20%;
      text-align: left;
      margin-top: 15px;
    }
    .master {
      margin-left: 20%;
      margin-right: 20%;
      margin-top: 15px;
      background-color: whitesmoke;
      padding: 1%;
    }
    .flexPawn3{

      width: 30%
  }

  .headPawn{
    margin-left: 0%;
    margin-top: 5px;
    font-size: large;
    font-weight: 700;
}
.subPawn{
    margin-top: 25px;
    font-size: medium;
    font-weight: 400;
}
  }
.page {
  background-color: lightgray;
}
.flexPawn2{
  width: 60%;
}
.flexKing{
    display: flex;
flex-wrap: wrap;
}
.flexPawn1{
width: 5%;
}

.headPawn{
    margin-top: 5px;
    font-size: large;
    font-weight: 700;
}
.subPawn{
    margin-top: 25px;
    font-size: medium;
    font-weight: 400;
}