.host {
  background-color: white;
  margin-right: 2%;
  margin-left: 2%;
}
.flex1 {
  display: flex;
}
.logoDiv {
  padding: 2%;
  width: 50%;
}
.logoImg {
  width: 100%;
}
.avasagDiv {
  align-items: center;
  padding: 2%;
  width: 50%;
  margin-left: 70%;
}
.text1 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  text-align: left;
}
.textDemo1 {
  width: 35%;
}
.textDemo2 {
  width: 50%;
}
.demo1 {
  margin-top: 5%;
}

.demo2 {
  height: 100%;
}

.web0 {
  width: 100%;
  opacity: 0.8;
  transition: 0.2s;
}
.web0:hover {
  opacity: 1;
  transform: scale3d(1.01, 1.02, 1);
  //  cursor: url('./avasag.png'), auto;
}

.web1 {
  width: 100%;
  opacity: 0.8;
  transition: 0.2s;
}

.web1:hover {
  opacity: 1;
  transform: scale3d(1.01, 1.02, 1);
  //  cursor: url('./avasag.png'), auto;
}
.textDemo2 {
  width: 50%;
  margin-left: -5%;
}
.text {
  padding: 2%;
  margin-top: 5%;
}
.head1 {
  font-weight: 700;
  color: #ff802f;
  font-size: x-large;
  text-align: left;
  margin-bottom: 20px;
}
.head2 {
  font-weight: 700;
  color: #ff802f;
  font-size: x-large;
  text-align: center;
  margin-bottom: 20px;
}
.middle {
  text-align: center;
  margin-top: 25%;
}
.title {
  font-size: 125px;
  color: #ff802f;
  font-weight: 800;
  text-align: center;
  line-height: 100px;
  opacity: 0;
  animation: fadeIn 1s ease-in both;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.imglimit {
  width: 500px;
}
.flexshow {
  display: flex;

  margin-top: 7%;
  margin-left: 1%;
}
.flexitem {
  margin-left: 5%;
}

.variantContainer {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.variantItem {
}

.video {
  width: 500px;
}

.video2 {
  width: 100%;
}
