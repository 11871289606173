@media (min-width: 350px) {
  .backgroundImage {
    width: 00%;
    height: 0px;
  }

  .divMenu {
    margin-left: 0%;
    margin-right: 0%;
  }

  .bruhMenu {
    margin-left: 10%;
    margin-right: 10%;
  }

  .logo1 {
    max-width: 35px;
  }


}

@media (min-width: 900px) {
  .divMenu {
    margin-left: 5%;
    margin-right: 5%;
  }

  .backgroundImage {
    width: 100%;
    height: 250px;

  }

  .backgroundImageContainer {
    margin-left: 5%;
    margin-right: 5%;
  }

  .searchbar {

    margin-bottom: 50%;
    margin-left: 50%;
    position: absolute;
  }
}

@media (min-width: 1400px) {
  .divMenu {
    margin-left: 10%;
    margin-right: 10%;
  }

  .backgroundImage {
    width: 100%;
    height: 250px;

  }

  .backgroundImageContainer {
    margin-left: 10%;
    margin-right: 10%;
  }

  .searchbar {

    margin-bottom: 50%;
    margin-left: 40%;
    position: absolute;
  }
}

@media (min-width: 1750px) {
  .divMenu {
    margin-left: 20%;
    margin-right: 20%;
  }

  .backgroundImage {
    width: 100%;
    height: 250px;
  }

  .backgroundImageContainer {
    margin-left: 20%;
    margin-right: 20%;
    border: 0;
  }

  .searchbar {

    margin-bottom: 50%;
    margin-left: 30%;
    position: absolute;
  }
}


.menuItem {
  font-weight: 1000;
  font-size: 18px;
}

.page {
  background-color: lightgray;
}

.avasagButton:hover {
  transform: scale(1.1);
}

.avasagButton {
  height: 30px;
}

.menuIcon {
  margin-right: 5px;
}

.phoneMenu {
  background-color: white;
}

.downHead {
  font-weight: 700;
  font-size: large;
}