@media (min-width: 350px) {
  .breadContain{
    margin-top: 15px;
    text-align: left;
    margin-left: 1%;
    margin-right: 1%;
  }
  .master{
    padding: 4%;
    margin-top: 15px;
    margin-left: 1%;
    margin-right: 1%;
    background-color: whitesmoke;
  }
  .flexPug1{
 
    width: 100%;
   }
  
  .flexPawn1{
   
   width: 100%;
  }
  .flexPawn2{

   width: 100%;
  }
}
@media (min-width: 900px) {
  .breadContain{
    margin-top: 15px;
    text-align: left;
    margin-left: 5%;
    margin-right: 5%;
  }
  .master{
    padding: 1%;
    margin-top: 15px;
    margin-left: 5%;
    margin-right: 5%;
    background-color: whitesmoke;
  }

}
@media (min-width: 1400px) {
  .breadContain{
    margin-top: 15px;
    text-align: left;
    margin-left: 10%;
    margin-right: 10%;
  }
  .master{
    padding: 1%;
    margin-top: 15px;
    margin-left: 10%;
    margin-right: 10%;
    background-color: whitesmoke;
  }
  .flexPug1{
 
    width: 80%;
   }
  
  .flexPawn1{
   
   width: 62%;
  }
  .flexPawn2{
    margin-left: 5%;
   width: 30%;
  }
}
@media (min-width: 1750px) {
  .breadContain{
    margin-top: 15px;
    text-align: left;
    margin-left: 20%;
    margin-right: 20%;
  }
  .master{
    padding: 1%;
    margin-top: 15px;
    margin-left: 20%;
    margin-right: 20%;
    background-color: whitesmoke;
  }
  .flexPug1{
 
    width: 80%;
   }
  
  .flexPawn1{
   
   width: 62%;
  }
  .flexPawn2{
    margin-left: 5%;
   width: 30%;
  }
}

.page{
  background-color: lightgray;
}
.flexKing{
  padding: 5px;
  display: flex;
  flex-wrap: wrap;
}

.pawnHead{
  cursor: pointer;
  margin-top: 1%;
  font-size: large;
}
.pawnSub{
  margin-top: 2%;
}
